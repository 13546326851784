import * as React from "react";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import style from "./FormCarousel.module.css";

export interface FormCarouselProps {
  index: number;
  components: React.ReactElement[],
}

enum TransitionState {
  NEUTRAL,
  LAST,
  NEXT
}

export function FormCarousel(props: FormCarouselProps) {
  const [index, setIndex] = React.useState(props.index);
  const transition = props.index < index && TransitionState.LAST || props.index > index && TransitionState.NEXT || TransitionState.NEUTRAL;

  React.useEffect(() => {
    if (transition !== TransitionState.NEUTRAL) {
      setTimeout(() => {
        setIndex(props.index);
      }, 550);
    }
  }, [transition]);



  const translation = `${((transition === TransitionState.NEUTRAL) && -100) || (transition === TransitionState.NEXT && -200) || (0)}vw`;

  const divRef = React.useRef<HTMLDivElement>();

  return (
    <div
      ref={divRef}
      className={style.carouselViewport}
      onClick={() => {
        divRef.current && divRef.current.scrollIntoView({
          behavior: 'smooth',
          block: "start"

        });
      }}
    >
      <div className={style.allImagesContainer}
        style={{
          width: "300vw",
          transform: `translate3d(${translation}, 0px, 0px)`,
          transition: transition !== TransitionState.NEUTRAL ? "transform 400ms ease" : undefined
        }}>
        {
          [
            <div key={transition === TransitionState.LAST && props.index || "LAST"} className={style.shadowContainer}><div className={style.divShadow}>{transition === TransitionState.LAST && props.components[props.index]}</div></div>,
            <div key={index} className={style.shadowContainer}><div className={style.divShadow}>{props.components[index]}</div></div>,
            <div key={transition === TransitionState.NEXT && props.index || "NEXT"} className={style.shadowContainer}><div className={style.divShadow}>{transition === TransitionState.NEXT && props.components[props.index]}</div></div>,
          ]
        }
      </div>
    </div>
  )
}