import React from "react"

import { graphql } from "gatsby"

import { Head } from "../../components/head/Head"
import ParallaxHooklessImage from "../../components/parallax-hookless-image/ParallaxHooklessImage"
import TextBlock from "../../components/text-block/TextBlock"

import "spinkit/spinkit.css";
import style from "./index.module.css";
import { FormCarousel } from "../../components/form-carousel/FormCarousel"
import { useTextField } from "../../hooks/TextField"

// import spinkit from ;


// const spinkit = require("../../../node_modules/spinkit/spinkit.css");


enum FormPage {
  YourName = 0,
  WillYouMakeIt = 1,
  Guests = 2,
  TheirName = 3,
  Food = 4,
  Vaccination = 5,
  Dumb = 6,
  Review = 7,
  Submitting = 8,
  Thanks = 9
}

enum MealChoice {
  Chicken = "Chicken",
  Pork = "Pork",
  Vegetarian = "Vegetarian",
}

enum SubmissionState {
  None,
  Submitting,
  Done,
  Error
}

enum Vaccinated {
  Vaccinated = "Vaccinated",
  Dumb = "Dumb"
}

export default ({ data }) => {
  const [index, setIndex] = React.useState(FormPage.YourName);

  const firstName = useTextField();
  const lastName = useTextField();
  const [willAttend, setWillAttend] = React.useState<boolean | null>(null);
  const [hasGuest, setHasGuest] = React.useState<boolean | null>(null);
  const guestFirstName = useTextField();
  const guestLastName = useTextField();

  const [mealChoice, setMealChoice] = React.useState<MealChoice | null>(null);
  const allergies = useTextField();
  const [guestMealChoice, setGuestMealChoice] = React.useState<MealChoice | null>(null);
  const guestAllergies = useTextField();

  const [vaccinated, setVaccinated] = React.useState<Vaccinated | null>(null);

  const [submissionState, setSubmissionState] = React.useState(SubmissionState.None);

  return (
    <React.Fragment>
      <Head title="Alex & Alexis - RSVP" imageSrc={data.portrait.childImageSharp.fluid.src} />
      <ParallaxHooklessImage
        src={data.portrait.childImageSharp.fluid.src}
        height={data.portrait.childImageSharp.fluid.presentationHeight}
        width={data.portrait.childImageSharp.fluid.presentationWidth}
      >
        <h1>RSVP</h1>
      </ParallaxHooklessImage>
      <TextBlock disablePadding={true}>
        <FormCarousel
          index={index}
          components={[
            <React.Fragment>
              <h2>Your name?</h2>
              <div>
                <div>
                  <label htmlFor="firstName">First name</label>
                </div>
                <input id="firstName" type="text" {...firstName} maxLength={20} />
              </div>
              <div>
                <div>
                  <label htmlFor="lastName">Last name</label>
                </div>
                <input id="lastName" type="text" {...lastName} maxLength={20} />
              </div>
              <button
                className={style.button}
                onClick={() => {
                  if (!firstName.value || !lastName.value) {
                    !firstName.value && firstName.setWarning();
                    !lastName.value && lastName.setWarning();
                    return;
                  }
                  setIndex(FormPage.WillYouMakeIt);
                }}
              >
                Next
              </button>
            </React.Fragment>,
            <React.Fragment>
              <h2>Will you make it?</h2>

              <span className={style.buttonsContainer}>
                <button
                  className={`${style.button} ${style.positiveButton} ${willAttend !== null && willAttend && style.selectedPositiveButton}`}
                  onClick={() => {
                    setWillAttend(true);
                    if (window.innerWidth < 600) {
                      setTimeout(() => {
                        setIndex(FormPage.Guests);
                      }, 500);
                    } else {
                      setIndex(FormPage.Guests);
                    }
                  }}
                >
                  Joyfully accepts
              </button>
                <button
                  className={`${style.button} ${style.negativeButton} ${willAttend !== null && !willAttend && style.selectedNegativeButton}`}
                  onClick={() => {
                    setWillAttend(false);
                    if (window.innerWidth < 600) {
                      setTimeout(() => {
                        setIndex(FormPage.Review);
                      }, 500);
                    } else {
                      setIndex(FormPage.Review);
                    }
                  }}
                >
                  Regretfully Declines
              </button>
              </span>
            </React.Fragment>,
            <React.Fragment>
              <h2>Anyone else?</h2>
              <span className={style.buttonsContainer}>
                <button className={style.button} onClick={() => { setHasGuest(false); setIndex(FormPage.Food); }}>Rolling solo</button>
                <button className={style.button} onClick={() => { setHasGuest(true); setIndex(FormPage.TheirName); }}>Going as a duo</button>
              </span>
            </React.Fragment>,
            <React.Fragment>
              <h2>Their name</h2>
              <div>
                <div>
                  <label htmlFor="guestFirstName">First name</label>
                </div>
                <input id="guestFirstName" type="text" {...guestFirstName} maxLength={20} />
              </div>
              <div>
                <div>
                  <label htmlFor="guestLastName">Last name</label>
                </div>
                <input id="guestLastName" type="text" {...guestLastName} maxLength={20} />
              </div>
              <button
                className={style.button}
                onClick={() => {
                  if (!guestFirstName.value || !guestLastName.value) {
                    !guestFirstName.value && guestFirstName.setWarning();
                    !guestLastName.value && guestLastName.setWarning();
                    return;
                  }
                  setIndex(FormPage.Food);
                }}
              >
                Next
                </button>
            </React.Fragment>,
            <React.Fragment>
              <h2>Meals</h2>
              <div>
                <label htmlFor="meal">
                  Your Meal
                  </label>
              </div>
              <select id="meal" onChange={(e) => setMealChoice(e.target.value as MealChoice)}>
                {mealChoice === null && <option selected>Select on Option</option>}
                <option value={MealChoice.Chicken}>{MealChoice.Chicken}</option>
                <option value={MealChoice.Pork}>{MealChoice.Pork}</option>
                <option value={MealChoice.Vegetarian}>{MealChoice.Vegetarian}</option>
              </select>

              <div>
                <label>Dietary restrictions or food allergies</label>
              </div>
              <textarea {...allergies} maxLength={120}></textarea>
              {
                hasGuest &&
                <div style={{ marginTop: "12px" }}>
                  <div>
                    {`${guestFirstName.value} ${guestLastName.value} Meal`}
                  </div>
                  <select value={guestMealChoice} onChange={(e) => setGuestMealChoice(e.target.value as MealChoice)}>
                    {guestMealChoice === null && <option selected>Select on Option</option>}
                    <option value={MealChoice.Chicken}>{MealChoice.Chicken}</option>
                    <option value={MealChoice.Pork}>{MealChoice.Pork}</option>
                    <option value={MealChoice.Vegetarian}>{MealChoice.Vegetarian}</option>
                  </select>

                  <div>
                    <label>Dietary restrictions or food allergies</label>
                  </div>
                  <textarea {...guestAllergies} maxLength={120}></textarea>
                </div>
              }
              <div>
                <button
                  className={style.button}
                  onClick={() => {
                    if (!mealChoice || (hasGuest && !guestMealChoice)) {
                      return;
                    }
                    setIndex(FormPage.Vaccination);
                  }}
                >
                  Next
                </button>
              </div>

            </React.Fragment>,
            <React.Fragment>
              <h2>{`Will you${hasGuest ? ` and ${guestFirstName.value}` : ""} be fully vaccinated by June 3rd?`}</h2>

              <span className={style.buttonsContainer}>
                <button
                  className={`${style.button} ${style.positiveButton} ${willAttend !== null && vaccinated === Vaccinated.Vaccinated && style.selectedPositiveButton}`}
                  onClick={() => {
                    setVaccinated(Vaccinated.Vaccinated);
                    if (window.innerWidth < 600) {
                      setTimeout(() => {
                        setIndex(FormPage.Review);
                      }, 500);
                    } else {
                      setIndex(FormPage.Review);
                    }
                  }}
                >
                  Yes {hasGuest ? "we" : "I"} will
              </button>
                <button
                  className={`${style.button} ${style.negativeButton} ${willAttend !== null && vaccinated === Vaccinated.Dumb && style.selectedNegativeButton}`}
                  onClick={() => {
                    setVaccinated(Vaccinated.Dumb);
                    if (window.innerWidth < 600) {
                      setTimeout(() => {
                        setIndex(FormPage.Dumb);
                      }, 500);
                    } else {
                      setIndex(FormPage.Dumb);
                    }
                  }}
                >
                  No {hasGuest ? "we" : "I"} will not
              </button>
              </span>
            </React.Fragment>,
            <React.Fragment>
              <h2>We will require all of our non-vaccinated guests to present a negative Covid-19 test dated within 48hrs of our wedding</h2>


              <span className={style.buttonsContainer}>
                <button className={`${style.button} ${style.buttonFreeWidth}`} onClick={() => { setIndex(FormPage.Review); }}>{hasGuest ? "We" : "I"} will be tested</button>
                <button className={`${style.button} ${style.buttonFreeWidth}`} onClick={() => { setWillAttend(false); setIndex(FormPage.Review); }}>{hasGuest ? "We" : "I"} do not plan to and will not attend</button>
              </span>
            </React.Fragment>,
            <React.Fragment>
              {
                submissionState !== SubmissionState.None && submissionState !== SubmissionState.Error &&
                <div
                  style={{
                    display: "fixed",
                    height: "100%",
                    width: "100%"
                  }}
                >
                </div>
              }
              <h2>Review</h2>
              {submissionState === SubmissionState.Error && <div >Error Submitting</div>}
              <h3>Your Name (<button className={style.editButton} onClick={() => setIndex(FormPage.YourName)}>Edit</button>)</h3>
              {`${firstName.value} ${lastName.value} `}
              <h3>{`${willAttend && "Will be" || "Will not be"} Attending`} (<button className={style.editButton} onClick={() => setIndex(FormPage.WillYouMakeIt)}>Edit</button>)</h3>
              {willAttend && (
                <React.Fragment>
                  <h3>{!hasGuest ? "Rolling Solo" : "Partner in Crime"} (<button className={style.editButton} onClick={() => setIndex(FormPage.Guests)}>Edit</button>)</h3>
                  {
                    hasGuest && `${guestFirstName.value} ${guestLastName.value}`
                  }
                  <h3>{hasGuest ? "Meals" : "Meal"} (<button className={style.editButton} onClick={() => setIndex(FormPage.Food)}>Edit</button>)</h3>
                  <div>
                    {`${firstName.value} ${lastName.value}: ${mealChoice}`}
                    <div>{``}</div>
                    <div>{allergies.value ? <div>Allergies: <span style={{ maxWidth: "300px" }}>{allergies.value}</span></div> : "No allergies"}</div>
                  </div>

                  {hasGuest && <div>
                    {`${guestFirstName.value} ${guestLastName.value}: ${guestMealChoice}`}
                    <div>{guestAllergies.value ? <div>Allergies: <span style={{ maxWidth: "300px" }}>{guestAllergies.value}</span></div> : "No allergies"}</div>
                  </div>}

                  <h3>Vaccinations</h3>
                  <div>
                    {vaccinated === Vaccinated.Vaccinated ? "Fully Vaccinated: Yay we can't wait to see you!" : "Will bring a valid test on the day of the wedding"}
                  </div>
                </React.Fragment>
              )
              }

              <button
                disabled={!(submissionState === SubmissionState.None || submissionState === SubmissionState.Error)}
                className={style.button}
                onClick={async () => {
                  setSubmissionState(SubmissionState.Submitting);
                  setIndex(FormPage.Submitting);
                  try {
                    const data = {
                      firstName: firstName.value,
                      lastName: lastName.value,
                      willAttend,
                      hasGuest,
                      guestFirstName: guestFirstName.value,
                      guestLastName: guestLastName.value,
                      mealChoice: mealChoice,
                      allergies: allergies.value,
                      guestMealChoice: guestMealChoice,
                      guestAllergies: guestAllergies.value,
                      vaccinated: vaccinated
                    }

                    const result = await fetch("https://qbwtza604e.execute-api.us-east-2.amazonaws.com/dev/rsvp", {
                      mode: "cors",
                      method: "PUT",
                      body: JSON.stringify(data)
                    });
                    setSubmissionState(SubmissionState.Done);
                    setIndex(FormPage.Thanks);

                    console.log(result.ok);
                    console.log(result);
                  } catch (e) {
                    setSubmissionState(SubmissionState.Error);
                    setIndex(FormPage.Review);
                    console.log(e)
                  }
                }}
              >
                Submit
                </button>
            </React.Fragment>,
            <div className={style.spinnerContainer}>
              <div className="sk-fold" style={{ marginLeft: "auto", marginRight: "auto" }}>
                <div className="sk-fold-cube"></div>
                <div className="sk-fold-cube"></div>
                <div className="sk-fold-cube"></div>
                <div className="sk-fold-cube"></div>
              </div>
            </div>,
            <React.Fragment>
              <h2>Done</h2>
              Thanks for RSVP'ing
              <div>
                Check out Hotel St. Louis for hotel accommodations in the <a href="https://www.marriott.com/events/start.mi?id=1604428577548&key=GRP" target="_blank">Eacret/David block</a>
              </div>
            </React.Fragment>
          ]}

        />
      </TextBlock>

    </React.Fragment >
  )
}

export const query = graphql`
  {
          portrait: file(relativePath: {eq: "zoe-portrait.jpg" }) {
          childImageSharp {
          fluid(maxWidth: 3440) {
          src
          presentationHeight
        presentationWidth
      }
    }
  }
}
`
