import { useState, useCallback, MouseEvent, ChangeEvent } from "react";


export function useTextField() {
  const [value, setValue] = useState("");
  const [style, setStyle] = useState({});
  const onChange = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setStyle({});
    setValue(e.target.value);
  }, []);

  const setWarning = useCallback(() => {
    setStyle({
      border: "1px",
      borderColor: "red",
      borderStyle: "solid"
    });
  }, []);

  return {
    value,
    style,
    onChange,
    setWarning
  };
}